// components
import FormValidation from '@/js/components/forms/formValidation';
import { statistics } from '@/js/api/jobs';
import DonutChart from '@/js/components/charts/donutChart';

class JobsDetail {
  constructor() {
    this.dom = {
      formContact: document.getElementById('form-job-registration'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
      chartEl: document.getElementById('summaryChart'),
    };

    this.components = {
      donutChart: null,
      loader: null,
    };

    this.data = {
      jobId: document.getElementById('jobID').value,
    };

    this.components = {
      contactFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formContact) {
      this.components.contactFormValidation = new FormValidation(this.dom.formContact);
    }

    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }

    this.loadChartData();
  }

  // eslint-disable-next-line class-methods-use-this
  loadChartData() {
    statistics(this.data.jobId)
      .then((response) => {
        if (response.data) {
          this.components.donutChart = new DonutChart(this.dom.chartEl, response.data);
        }
      })
      .finally(() => {});
  }
}

if (document.getElementById('page-jobs-detail')) {
  // eslint-disable-next-line no-new
  new JobsDetail();
}
