import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

export function statistics(id) {
  return axios.get(`${rootWithLanguage}chart-data/${id}`);
}

export default {
  statistics,
};
