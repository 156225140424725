import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const elements = gsap.utils.toArray('.anim-fade-in');

elements.forEach((el) => {
  const anim = gsap.fromTo(el, { autoAlpha: 0, y: 50 }, { duration: 2, autoAlpha: 1, y: 0 });
  ScrollTrigger.create({
    trigger: el,
    animation: anim,
    toggleActions: 'play none none none',
    once: true,
  });
});
