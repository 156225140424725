import { Chart } from 'chart.js/auto';
import htmlLegendPlugin from '@/js/components/charts/plugins/legend';

export default class DonutChart {
  /**
   * @param canvas {HTMLCanvasElement}
   * @param data {array}
   */
  constructor(canvas, data) {
    this.dom = {
      canvas,
    };

    this.components = {
      chart: undefined,
    };

    this.props = {
      data,
    };

    this.data = {
      backgroundColors: [
        '#E0ECF4',
        '#91c8ec',
        '#4984a8',
        '#114060',
        '#042d44',
        '#5f968a',
        '#326e63',
        '#023d33',
        '#0b2620',
        '#000000',
      ],
      defaults: {
        font: {
          family: 'mundial, helvetica, arial, sans-serif',
          size: 12,
          weight: 500,
        },
      },
    };

    this.mount();
  }

  mount() {
    if (this.dom.canvas) {
      this.initializeChart();
    }
  }

  initializeChart() {
    Chart.defaults = { ...Chart.defaults, ...this.data.defaults };

    this.components.chart = new Chart(
      this.dom.canvas,
      {
        type: 'doughnut',
        data: {
          labels: Object.keys(this.props.data),
          datasets: [{
            data: Object.values(this.props.data),
            backgroundColor: this.data.backgroundColors,
          }],
        },
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'summary-chart-legend',
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          events: [],
        },
        plugins: [htmlLegendPlugin],
      },
    );
  }
}
