export default class AnimSearchGlass {
  constructor() {
    this.dom = {
      sections: document.querySelectorAll('.anim-search-glass'),
      document: document.documentElement,
    };

    this.prop = {
      viewportBreakpoint: window.matchMedia('(min-width: 991px)'),
      mouseX: 0,
      mouseY: 0,
    };

    this.mount();
  }

  mount() {
    this.dom.document.style.setProperty('--mouse-pos-x', '-10000px');
    this.dom.document.style.setProperty('--mouse-pos-y', '-10000px');

    this.prop.viewportBreakpoint.addEventListener('change', () => {
      this.mediaQueryCheck();
    });

    this.mediaQueryCheck();
  }

  // eslint-disable-next-line class-methods-use-this
  mediaQueryCheck() {
    if (this.prop.viewportBreakpoint.matches) {
      this.dom.sections.forEach((section) => {
        this.backgroundHover(section);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  backgroundHover(section) {
    section.addEventListener('mousemove', (e) => {
      this.prop.mouseX = e.pageX - section.offsetLeft;
      this.prop.mouseY = e.pageY - section.offsetTop;
      this.dom.document.style.setProperty('--mouse-pos-x', `${this.prop.mouseX}px`);
      this.dom.document.style.setProperty('--mouse-pos-y', `${this.prop.mouseY}px`);
    });
    section.addEventListener('mouseleave', () => {
      this.dom.document.style.setProperty('--mouse-pos-x', '-1000px');
      this.dom.document.style.setProperty('--mouse-pos-y', '-1000px');
    });
  }
}
