const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('div');

  if (!listContainer) {
    listContainer = document.createElement('div');
    listContainer.classList.add('chart-legend');
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',

  createLegendItems(legendItems, container) {
    legendItems.forEach((item) => {
      const div = document.createElement('div');
      div.classList.add('chart-legend-item');

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.classList.add('badge');
      boxSpan.style.background = item.fillStyle;

      // Text
      const textContainer = document.createElement('span');
      textContainer.classList.add('label');
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      div.appendChild(boxSpan);
      div.appendChild(textContainer);
      container.appendChild(div);
    });
  },

  afterUpdate(chart, args, options) {
    const wrapper = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (wrapper.firstChild) {
      wrapper.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    // Split the items array into two halves
    const middleIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, middleIndex);
    const secondHalf = items.slice(middleIndex);

    // Create a wrapper for the first half
    const firstDiv = document.createElement('div');
    firstDiv.classList.add('chart-legend-first-half');

    // Create a wrapper for the second half
    const secondDiv = document.createElement('div');
    secondDiv.classList.add('chart-legend-second-half');

    // Create legend items for each half and append to the respective containers
    this.createLegendItems(firstHalf, firstDiv);
    this.createLegendItems(secondHalf, secondDiv);

    // Append the created divs to the wrapper
    wrapper.appendChild(firstDiv);
    wrapper.appendChild(secondDiv);
  },
};

export default htmlLegendPlugin;
