import { Modal } from 'bootstrap';
import { root } from '@/js/utils/paths';
import { lang } from '@/js/utils/locales';
import createCookie from './createCookie';

export default class CookiesNotice {
  constructor(cookieNoticeElement, modal) {
    this.dom = {
      cookieNotice: cookieNoticeElement,
      modal,
      form: document.querySelector('form[name="CookieNotice"]'),
      triggers: [...document.querySelectorAll('.js-cookie-trigger')],
    };

    this.data = {
      url: `${root}${lang}/privacy`,
      formData: null,
      loadingClass: 'is-loading',
      acceptAllCookies: 'accept-all-cookies',
    };

    this.events = {
      setCookies: this.setCookies.bind(this),
      onModalHidden: this.onModalHidden.bind(this),
    };

    this.components = {
      modal: null,
    };

    this.mount();
  }

  mount() {
    this.dom.triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.events.setCookies);
    });
  }

  // remove from dom
  destroy() {
    if (this.dom.cookieNotice) this.dom.cookieNotice.remove();
  }

  // set to loading state
  setLoadingState() {
    // hide the notice
    if (this.dom.cookieNotice) this.dom.cookieNotice.classList.add(this.data.loadingClass);
  }

  // set to default state
  resetState() {
    // hide the notice
    if (this.dom.cookieNotice) this.dom.cookieNotice.classList.remove(this.data.loadingClass);
  }

  /**
   * Event is fired when the modal has finished being hidden from the user
   * (will wait for CSS transitions to complete).
   */
  onModalHidden() {
    this.dom.modal.removeEventListener('hidden.bs.modal', this.events.onModalHidden);
    this.components.modal.dispose();
    this.destroy();
  }

  /**
   * set cookie
   * @param e {Event}
   */
  setCookies(e) {
    e.preventDefault();

    // allow interaction
    this.setLoadingState();
    const { target } = e;
    const action = target.dataset.name;
    const cookieDuration = 30;
    // set form data
    this.data.formData = new FormData(this.dom.form);
    this.data.formData.append('action', action);

    if (this.dom.modal) this.components.modal = Modal.getInstance(this.dom.modal);
    document.querySelectorAll('.privacy-settings-checkbox:not(:disabled)').forEach((checkbox) => {
      const cookieName = checkbox.getAttribute('data-key');
      const value = +(action === 'accept-all-cookies' || checkbox.checked);
      createCookie(cookieName, value, cookieDuration);
    });

    createCookie('cookies_selected', 1, cookieDuration);

    if (this.components.modal) {
      // hide model, then remove from dom
      this.dom.modal.addEventListener('hidden.bs.modal', this.events.onModalHidden);
      this.components.modal.hide();
    } else {
      // all done, remove from dom
      this.destroy();
    }
  }
}
