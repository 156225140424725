import FormValidation from '@/js/components/forms/formValidation';
import Swiper, {
  Autoplay, EffectFade, Lazy, Navigation, Pagination,
} from 'swiper/core';
import { gsap } from 'gsap';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

class Home {
  constructor() {
    this.dom = {
      swiperIntro: document.getElementById('swiper-intro'),
      subscriberForm: document.getElementById('subscriber-form'),
      heroCaption: document.getElementById('hero-caption'),
    };

    this.data = {
      swiperConfig: {
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 3500,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
        effect: 'fade',
      },
    };

    this.components = {
      swiperInstance: null,
      subscriberForm: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.swiperIntro) {
      this.components.swiperInstance = new Swiper(this.dom.swiperIntro, this.data.swiperConfig);
    }

    if (this.dom.subscriberForm) {
      this.components.subscriberFormValidation = new FormValidation(this.dom.subscriberForm);
    }

    gsap.from(this.dom.heroCaption, {
      x: -200,
      opacity: 0,
      duration: 0.5,
      delay: 0.5,
      ease: 'power1.out',
    });
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
