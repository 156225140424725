import { getViews } from '@/js/api/routing';

class UrlConfigurator {
  constructor() {
    this.dom = {
      controllerSelector: document.getElementById('controller'),
      viewSelector: document.getElementById('view'),
    };

    this.mount();
  }

  mount() {
    this.dom.controllerSelector.addEventListener('change', (e) => this.updateViews(e.target.value));
  }

  updateViews(controller) {
    // fetch file name regex
    getViews(controller)
      // success
      .then((response) => {
        const views = response.data;

        // Remove existing options
        [...this.dom.viewSelector.children].forEach((option) => {
          // Remove the option when not default
          if (option.value !== '') {
            option.remove();
          }
        });

        // Update the select with the available views
        views.forEach((view) => {
          this.dom.viewSelector.options[this.dom.viewSelector.options.length] = new Option(view);
        });
      })
      // fail
      .catch((error) => {
        console.error(error.response);
      });
  }
}

if (document.getElementById('page-url-configurator')) {
  // eslint-disable-next-line no-new
  new UrlConfigurator();
}
